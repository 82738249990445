import service from "@/api/service";
import { GetResponse } from "@/api/category/response";
import { GetRequest, RegisterRequest } from "@/api/category/request";
import { CommonResponse } from "@/api/response";

/**
 * カテゴリジャンル取得APIをコールします。
 *
 * @param getRequest
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-category", getRequest);
  return response.data as GetResponse;
}

/**
 * カテゴリ情報登録更新API
 *
 * @param registerRequest
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-category", registerRequest);
  return response.data as CommonResponse;
}
