import service from "@/api/service";
import { DetailRequest } from "@/api/shop-category/request";
import { DetailResponse } from "@/api/shop-category/response";

/**
 * カテゴリ情報取得APIをコールします。
 *
 * @param detailRequest 検索のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/search-shop-category", detailRequest);
  return response.data as DetailResponse;
}
