import service from "@/api/service";
import {
  DeleteRequest,
  RegisterRequest,
  SearchRequest
} from "@/api/large-category/request";
import { SearchResponse } from "@/api/large-category/response";
import { CommonResponse } from "@/api/response";

/**
 * 大ジャンル一覧・検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-large-category", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 大ジャンル情報登録更新APIをコールします。
 *
 * @param registerRequest
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-large-category",
    registerRequest
  );
  return response.data as CommonResponse;
}

/**
 * 大ジャンル情報削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-large-category", deleteRequest);
  return response.data as CommonResponse;
}
