import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { TableOptions } from "@/api/request";
import Sortable from "sortablejs";
import ShopCategoryDetail from "@/store/shop-category/detail";
import LargeCategorySearch, {
  ListItem as LargeListItem
} from "@/store/large-category/search";
import SmallCategorySearch, {
  ListItem as SmallListItem
} from "@/store/small-category/search";
import LargeCategoryModify from "@/store/large-category/modify";
import SmallCategoryModify from "@/store/small-category/modify";
import CategoryRegister from "@/store/category/register";
import Flash from "@/store/common/flash";
import { RegisterRequest as LargeCategoryRegisterRequest } from "@/api/large-category/request";
import { RegisterRequest as SmallCategoryRegisterRequest } from "@/api/small-category/request";
import { RegisterRequest as CategoryRegisterRequest } from "@/api/category/request";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UIDataTable,
    UIDialogDelete
  }
})
export default class Edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string;

  // タイトル
  headingMain = "支店カテゴリー設定";
  headingSub = "Branch store category setting";

  // パンくず
  breadCrumbs = [
    { text: "マスターデータ管理", disabled: true },
    { text: "支店カテゴリー", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "category" }
    },
    { text: "編集", disabled: true }
  ];

  // 0: 都道府県、1: フリーワード
  categoryType = 0 as number;

  // 大ジャンル
  isLargeCategoryLoading = false;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  largeCategoryTableOptions = {} as TableOptions;

  largeCategoryHeaders = [
    { text: "", always: true, value: "reorder", sortable: false },
    { text: "大ジャンル名", value: "name", sortable: false },
    { text: "状態", value: "viewFlg", sortable: false },
    { text: "", value: "space", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  get largeCategoryCount() {
    return LargeCategorySearch.getItems.length;
  }

  get largeCategoryItems() {
    return LargeCategorySearch.getItems;
  }

  get largeCategoryWriteFlg() {
    return LargeCategorySearch.getWriteFlg == 1;
  }

  // 小ジャンル
  isSmallCategoryLoading = false;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  smallCategoryTableOptions = {} as TableOptions;

  smallCategoryHeaders = [
    { text: "", always: true, value: "reorder", sortable: false },
    { text: "大ジャンル名", value: "parentCategoryName", sortable: false },
    { text: "小ジャンル名", value: "categoryName", sortable: false },
    { text: "状態", value: "viewFlg", sortable: false },
    { text: "", value: "space", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  get smallCategoryCount() {
    return SmallCategorySearch.getItems.length;
  }

  // v-data-tableのitem-keyのdefaultはid
  get smallCategoryItems() {
    return SmallCategorySearch.getItems;
  }

  get smallCategoryWriteFlg() {
    return SmallCategorySearch.getWriteFlg == 1;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  // delete dialog
  showDeleteDialog = false as boolean;
  deletingName = "" as string;
  deletingLargeCategoryId = 0 as number;
  deletingSmallCategoryId = 0 as number;

  // large category new/edit dialog
  showLargeCategoryEditDialog = false as boolean;
  editLargeCategoryId = 0 as number;
  editLargeCategoryName = "" as string;
  editLargeCategoryViewFlg = 1 as number;

  // small category new/edit dialog
  showSmallCategoryEditDialog = false as boolean;
  editSmallParentCategoryId = 0 as number;
  editSmallCategoryId = 0 as number;
  editSmallCategoryName = "" as string;
  editSmallCategoryViewFlg = 1 as number;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  async created() {
    await ShopCategoryDetail.detail({ shopId: Number(this.id) });
    if (!ShopCategoryDetail.isSuccess) {
      await Flash.setErrorNow({
        message: ShopCategoryDetail.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.categoryType = ShopCategoryDetail.getCategoryType;
    await Promise.all([this.searchLargeCategory(), this.searchSmallCategory()]);
  }

  mounted() {
    this.$nextTick(() => {
      const _self = this;

      const mainTable = document.querySelector(
        ".v-data-table.large-category tbody"
      ) as HTMLElement;
      Sortable.create(mainTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.largeCategoryItems.splice(
            oldIndex as number,
            1
          )[0];
          _self.largeCategoryItems.splice(newIndex as number, 0, rowSelected);
        }
      });

      const subTable = document.querySelector(
        ".v-data-table.small-category tbody"
      ) as HTMLElement;
      Sortable.create(subTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.smallCategoryItems.splice(
            oldIndex as number,
            1
          )[0];
          _self.smallCategoryItems.splice(newIndex as number, 0, rowSelected);
        }
      });
    });
  }

  async onNewLargeCategory() {
    this.showLargeCategoryEditDialog = true;
    this.editLargeCategoryId = 0;
    this.editLargeCategoryName = "";
    this.editLargeCategoryViewFlg = 1;
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async largeTableChangeCallback(tableOptions: TableOptions) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async largeEditClickCallback(item: LargeListItem) {
    this.showLargeCategoryEditDialog = true;
    this.editLargeCategoryId = item.id;
    this.editLargeCategoryName = item.name;
    this.editLargeCategoryViewFlg = item.rawViewFlg;
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  largeDeleteClickCallback(item: LargeListItem) {
    this.showDeleteDialog = true;
    this.deletingName = item.name;
    this.deletingLargeCategoryId = item.id;
    this.deletingSmallCategoryId = 0;
  }

  async onNewSmallCategory() {
    this.showSmallCategoryEditDialog = true;
    this.editSmallParentCategoryId = 0;
    this.editSmallCategoryId = 0;
    this.editSmallCategoryName = "";
    this.editSmallCategoryViewFlg = 1;
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async smallTableChangeCallback(tableOptions: TableOptions) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async smallEditClickCallback(item: SmallListItem) {
    this.showSmallCategoryEditDialog = true;
    this.editSmallParentCategoryId = item.parentCategoryId;
    this.editSmallCategoryId = item.categoryId;
    this.editSmallCategoryName = item.categoryName;
    this.editSmallCategoryViewFlg = item.rawViewFlg;
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  smallDeleteClickCallback(item: SmallListItem) {
    this.showDeleteDialog = true;
    this.deletingName = item.categoryName;
    this.deletingSmallCategoryId = item.id;
    this.deletingLargeCategoryId = 0;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDeleteDialog = false;
    if (this.deletingLargeCategoryId > 0) {
      this.isLargeCategoryLoading = true;
      await LargeCategoryModify.deleteOne({
        categoryId: this.deletingLargeCategoryId
      });
      this.isLargeCategoryLoading = false;
      if (LargeCategoryModify.isSuccess) {
        await Flash.setSuccessNow({
          message: "大ジャンルを削除しました。",
          consumePath: ""
        });
        await this.searchLargeCategory();
      } else {
        await Flash.setErrorNow({
          message: LargeCategoryModify.getMessage,
          showReloadButton: false
        });
      }
    } else if (this.deletingSmallCategoryId > 0) {
      this.isSmallCategoryLoading = true;
      await SmallCategoryModify.deleteOne({
        categoryId: this.deletingSmallCategoryId
      });
      this.isSmallCategoryLoading = false;
      if (SmallCategoryModify.isSuccess) {
        await Flash.setSuccessNow({
          message: "小ジャンルを削除しました。",
          consumePath: ""
        });
        await this.searchSmallCategory();
      } else {
        await Flash.setErrorNow({
          message: SmallCategoryModify.getMessage,
          showReloadButton: false
        });
      }
    } else {
      return;
    }
  }

  async doSaveOnLargeEditDialog() {
    this.showLargeCategoryEditDialog = false;
    const request = {
      shopId: Number(this.id),
      name: this.editLargeCategoryName,
      viewFlg: this.editLargeCategoryViewFlg
    } as LargeCategoryRegisterRequest;
    if (this.editLargeCategoryId > 0) {
      request.categoryId = this.editLargeCategoryId;
    }

    this.isLargeCategoryLoading = true;
    await LargeCategoryModify.register(request);
    this.isLargeCategoryLoading = false;
    if (LargeCategoryModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "大ジャンルを保存しました。",
        consumePath: ""
      });
      await this.searchLargeCategory();
    } else {
      await Flash.setErrorNow({
        message: LargeCategoryModify.getMessage,
        showReloadButton: false
      });
    }
  }

  async doSaveOnSmallEditDialog() {
    this.showSmallCategoryEditDialog = false;
    const request = {
      shopId: Number(this.id),
      parentCategoryId: this.editSmallParentCategoryId,
      name: this.editSmallCategoryName,
      viewFlg: this.editSmallCategoryViewFlg
    } as SmallCategoryRegisterRequest;
    if (this.editSmallCategoryId > 0) {
      request.categoryId = this.editSmallCategoryId;
    }

    this.isSmallCategoryLoading = true;
    await SmallCategoryModify.register(request);
    this.isSmallCategoryLoading = false;
    if (SmallCategoryModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "小ジャンルを保存しました。",
        consumePath: ""
      });
      await this.searchSmallCategory();
    } else {
      await Flash.setErrorNow({
        message: SmallCategoryModify.getMessage,
        showReloadButton: false
      });
    }
  }

  // /** カテゴリ種類 */
  // categoryType: number;
  // /** 大カテゴリのソート */
  // largeCategories: null | RegisterCategory[];
  // /** 小カテゴリのソート */
  // smallCategories: null | RegisterCategory[];
  async onSaveCategory() {
    const request = {
      shopId: Number(this.id),
      categoryType: this.categoryType
    } as CategoryRegisterRequest;
    request.largeCategoryIds = this.largeCategoryItems.map(item => item.id);
    request.smallCategoryIds = this.smallCategoryItems.map(item => item.id);
    await CategoryRegister.register(request);
    if (CategoryRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "支店カテゴリ設定を保存しました。",
        "/category"
      );
    } else {
      await Flash.setErrorNow({
        message: CategoryRegister.getMessage,
        showReloadButton: false
      });
    }

    return request;
  }

  async searchLargeCategory() {
    this.isLargeCategoryLoading = true;
    await LargeCategorySearch.search({
      shopId: Number(this.id)
    });
    this.isLargeCategoryLoading = false;
    if (!LargeCategorySearch.isSuccess) {
      await Flash.setErrorNow({
        message: LargeCategorySearch.getMessage,
        showReloadButton: true
      });
      return;
    }
  }

  async searchSmallCategory() {
    this.isSmallCategoryLoading = true;
    await SmallCategorySearch.search({
      shopId: Number(this.id)
    });
    this.isSmallCategoryLoading = false;
    if (!SmallCategorySearch.isSuccess) {
      await Flash.setErrorNow({
        message: SmallCategorySearch.getMessage,
        showReloadButton: true
      });
      return;
    }
  }
}
