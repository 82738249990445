import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as LargeCategoryAPI from "@/api/large-category";
import { isSuccess } from "@/api/response";
import { SearchResponse } from "@/api/large-category/response";
import { SearchRequest } from "@/api/large-category/request";
import { SearchItem } from "@/api/large-category/response";

const MODULE_NAME = "large-category/search";

/**
 * 大カテゴリのデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get id() {
    return this._item.id;
  }
  get name() {
    return this._item.name;
  }
  get viewFlg() {
    return this._item.viewFlg == 1 ? "公開" : "非公開";
  }
  get rawViewFlg() {
    return this._item.viewFlg;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * 大ジャンル一覧・検索API（/search-large-category）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      // return this.searchResponse.results.items || [];
      return (this.searchResponse.results.items || []).map(
        value => new ListItem(value)
      );
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await LargeCategoryAPI.search(searchRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
